import { AxiosPromise } from 'axios';
import * as H from 'history';
import {
  Me,
  LoginRequest,
  RequestPasswordResetRequest,
  VerifyPasswordResetTokenRequest,
  PasswordResetRequest,
  VerifyActivationTokenRequest,
  ActivationRequest,
  LoginResponse,
  IdentityProviderMetadata,
  IDENTITY_PROVIDER_TYPE_METADATA,
} from '../types';
import { ACCESS_TOKEN, AXIOS } from './endpoints';

export const LOGIN_PATHNAME = 'loginPathname';
export const LOGIN_SEARCH = 'loginSearch';

export const hasJWT = () => !!localStorage.getItem(ACCESS_TOKEN);

export function verifyActivationToken(request: VerifyActivationTokenRequest): AxiosPromise<never> {
  return AXIOS.post('/auth/verify_activation_token', request);
}

export function activate(activationRequest: ActivationRequest): AxiosPromise<never> {
  return AXIOS.post('/auth/activate', activationRequest);
}

export function login(request: LoginRequest): AxiosPromise<LoginResponse> {
  return AXIOS.post('/auth/login', request);
}

export function requestPasswordReset(request: RequestPasswordResetRequest): AxiosPromise<never> {
  return AXIOS.post('/auth/request_password_reset', request);
}

export function verifyPasswordResetToken(
  request: VerifyPasswordResetTokenRequest
): AxiosPromise<never> {
  return AXIOS.post('/auth/verify_password_reset_token', request);
}

export function passwordReset(request: PasswordResetRequest): AxiosPromise<never> {
  return AXIOS.post('/auth/password_reset', request);
}

export function storeLoginRedirect(location?: H.Location) {
  if (location) {
    localStorage.setItem(LOGIN_PATHNAME, location.pathname);
    localStorage.setItem(LOGIN_SEARCH, location.search);
  }
}

export function clearLoginRedirect() {
  localStorage.removeItem(LOGIN_PATHNAME);
  localStorage.removeItem(LOGIN_SEARCH);
}

export function fetchLoginRedirect(user: Me): Partial<H.Path> {
  const loginPathname = localStorage.getItem(LOGIN_PATHNAME);
  const loginSearch = localStorage.getItem(LOGIN_SEARCH);
  clearLoginRedirect();
  return {
    pathname: loginPathname || (user.receiverPermissions ? '/projects/' : '/my_assignments/'),
    search: (loginPathname && loginSearch) || undefined,
  };
}

export function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN);
}

export function generateIdentityProviderAuthenticationUrl({ type, key }: IdentityProviderMetadata) {
  return IDENTITY_PROVIDER_TYPE_METADATA[type].authenticationPath(key);
}
