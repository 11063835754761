import { intl } from '../Internationalization';
import {
  PageResponse,
  SimplePageRequest,
  AuditEventDetail,
  VirusCheckedMediaDetail,
} from './shared';
import { SubmissionAutomationMode } from './submission';

export interface CreateProjectRequest extends ProjectSettings {
  key: string;
}

export interface ProjectSettings {
  name: string;
  description: string;
  active: boolean;
  imageStyle: ImageStyle;
}

export interface ProjectSpecificationDefaults {
  defaultAutomationMode: SubmissionAutomationMode;
  defaultNotifications: boolean;
}

export interface ProjectMetadata {
  key: string;
  name: string;
  description: string;
  image?: VirusCheckedMediaDetail;
  imageStyle: ImageStyle;
}

export enum ImageStyle {
  FIT = 'FIT',
  CROP = 'CROP',
}

export const imageStyles = [ImageStyle.CROP, ImageStyle.FIT];

export const IMAGE_STYLE_METADATA: Record<ImageStyle, { label: string }> = {
  FIT: {
    label: intl.formatMessage({ id: 'project.imageStyle.fit.label', defaultMessage: 'Fit' }),
  },
  CROP: {
    label: intl.formatMessage({ id: 'project.imageStyle.crop.label', defaultMessage: 'Crop' }),
  },
};

export interface ProjectDetail extends ProjectMetadata {
  active: boolean;
  createEvent: AuditEventDetail;
  updateEvent: AuditEventDetail;
  defaultAutomationMode: SubmissionAutomationMode;
  defaultNotifications: boolean;
}

export interface ProjectsRequest extends SimplePageRequest {
  filter?: string;
  active?: boolean;
}

export type ProjectsResponse = PageResponse<ProjectDetail>;
